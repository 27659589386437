<template>
  <div id="mask">
    <el-dialog
      :title="maskFormTitle"
      width="800px"
      :visible.sync="maskDialogVisible"
      :close-on-click-modal="false"
      @close="maskDialogClose"
    >
      <el-form
        ref="maskFormRef"
        :model="maskForm"
        :rules="maskFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="检查日期" prop="checkDate">
              <el-date-picker
                v-model="maskForm.checkDate"
                type="month"
                placeholder="请选择检查日期"
                format="yyyy年MM月"
                value-format="yyyy年MM月"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="周期" prop="week">
              <el-select v-model="maskForm.week" placeholder="请选择周期" clearable>
                <el-option
                  v-for="item in weekList"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预防性维护" prop="project">
              <el-input
                v-model="maskForm.project"
                placeholder="请输入预防性维护"
                type="textarea"
                :rows="6"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实际维护情况" prop="actualSituation">
              <el-input
                v-model="maskForm.actualSituation"
                placeholder="请输入实际维护情况"
                type="textarea"
                :rows="6"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作人" prop="operator">
              <el-input v-model="maskForm.operator" placeholder="请输入编制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="maskForm.reviewer" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="maskForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="maskDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="maskFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="检查日期">
        <el-date-picker
          v-model="searchForm.checkDate"
          type="month"
          placeholder="请选择检查日期"
          format="yyyy年MM月"
          value-format="yyyy年MM月"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="maskPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="checkDate" label="检查日期" width="120" />
      <el-table-column prop="week" label="周期" width="80" />
      <el-table-column prop="project" label="预防性维护" />
      <el-table-column prop="actualSituation" label="实际维护情况" />
      <el-table-column prop="operator" label="操作人" width="80" />
      <el-table-column prop="reviewer" label="复核人" width="80" />
      <el-table-column prop="remarks" label="备注" width="120" />
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="maskPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMask, deleteMask, updateMask, selectMaskInfo, selectMaskList } from '@/api/device/mask'

export default {
  data () {
    return {
      maskDialogVisible: false,
      maskFormTitle: '',
      maskForm: {
        id: '',
        checkDate: '',
        week: '',
        project: '1、检查部件完好情况：查看紧固部件有无松动开关是否正常工作，如有损坏，应及时更换。\n'
          + '2、对设备进行一次大清理，清除所有的积粉。把各个机构用干净的干布擦拭干净。',
        actualSituation: '',
        operator: '',
        reviewer: '',
        remarks: ''
      },
      maskFormRules: {
        checkDate: [{ required: true, message: '检查日期不能为空', trigger: ['blur', 'change']}],
        week: [{ required: true, message: '周期不能为空', trigger: ['blur', 'change']}],
        actualSituation: [{ required: true, message: '实际维护情况不能为空', trigger: ['blur', 'change']}],
        operator: [{ required: true, message: '操作人不能为空', trigger: ['blur', 'change']}],
        reviewer: [{ required: true, message: '复核人不能为空', trigger: ['blur', 'change']}]
      },
      maskPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        checkDate: ''
      },
      weekList: ['第一周', '第二周', '第三周', '第四周', '第五周']
    }
  },
  created () {
    selectMaskList(this.searchForm).then(res => {
      this.maskPage = res
    })
  },
  methods: {
    maskDialogClose () {
      this.$refs.maskFormRef.resetFields()
    },
    maskFormSubmit () {
      if (this.maskFormTitle === '口罩机每周维护检查表详情') {
        this.maskDialogVisible = false
        return
      }
      this.$refs.maskFormRef.validate(async valid => {
        if (valid) {
          if (this.maskFormTitle === '新增口罩机每周维护检查表') {
            await addMask(this.maskForm)
          } else if (this.maskFormTitle === '修改口罩机每周维护检查表') {
            await updateMask(this.maskForm)
          }
          this.maskPage = await selectMaskList(this.searchForm)
          this.maskDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.maskFormTitle = '新增口罩机每周维护检查表'
      this.maskDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMask(row.id)
        if (this.maskPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.maskPage = await selectMaskList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.maskFormTitle = '修改口罩机每周维护检查表'
      this.maskDialogVisible = true
      this.selectMaskInfoById(row.id)
    },
    handleInfo (index, row) {
      this.maskFormTitle = '口罩机每周维护检查表详情'
      this.maskDialogVisible = true
      this.selectMaskInfoById(row.id)
    },
    selectMaskInfoById (id) {
      selectMaskInfo(id).then(res => {
        this.maskForm.id = res.id
        this.maskForm.checkDate = res.checkDate
        this.maskForm.week = res.week
        this.maskForm.project = res.project
        this.maskForm.actualSituation = res.actualSituation
        this.maskForm.operator = res.operator
        this.maskForm.reviewer = res.reviewer
        this.maskForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaskList(this.searchForm).then(res => {
        this.maskPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaskList(this.searchForm).then(res => {
        this.maskPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaskList(this.searchForm).then(res => {
        this.maskPage = res
      })
    }
  }
}
</script>

<style>
#mask .el-table .cell {
  white-space: pre-line;
}
</style>
